import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p>{`今、David C. Baker著、The Business of Expertiseという本を読んでいて、その中でPattern Matchingという概念について書かれている。それがなかなか良い話だった。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3s4J0LX",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`The Business of Expertise by David C. Baker`}</a></li>
      </ul>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "pattern-matching-is-何"
      }}>{`Pattern Matching is 何`}</h2>
      <p>{`開発者的にはPattern Matchingと言われたらまぁこういうやつでしょうよというところかと思う。`}</p>
      <pre {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token regex"
          }}><span parentName="span" {...{
              "className": "token regex-delimiter"
            }}>{`/`}</span><span parentName="span" {...{
              "className": "token regex-source language-regex"
            }}>{`.+\\.png`}</span><span parentName="span" {...{
              "className": "token regex-delimiter"
            }}>{`/`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token method function property-access"
          }}>{`test`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"my-dog.png"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// true`}</span>{`
`}</code></pre>
      <p>{`正規表現だったりシェルコマンドでワイルドカードを使ったりなんなりで、文字列を書いたルールにマッチさせる仕組みみたいな。`}</p>
      <p>{`で、この本で書いてあるのは、The Business of Expertise、無理やり日本語に直すと「専門家のビジネス」には、このPattern Matchingが必要であるとのこと。当然、正規表現を覚えろという話ではなく、もっと広い概念の話をしている。`}</p>
      <p>{`例えば赤ん坊に、`}</p>
      <ul>
        <li parentName="ul">{`A: アヒルの写真`}</li>
        <li parentName="ul">{`B: アヒルの写真`}</li>
        <li parentName="ul">{`C: ガチョウの写真`}</li>
      </ul>
      <p>{`を見せたら、Cを指差したりする。これがPattern Matchingであると。与えられた情報にルールを見つけ出し、それを認識するみたいな、そういうことをするぞと。そして、知性というのは本質的にPattern Matchingなんだということも書いてある。`}</p>
      <h2 {...{
        "id": "音楽を聞くこと"
      }}>{`音楽を聞くこと`}</h2>
      <p>{`まぁそれ以降も色々とこの本には書いてあるのだけれど、まずここまでで自分は、あーそうだよなーと思うことがたくさんあった。この例はほぼ無限にある。`}</p>
      <p>{`例えば音楽を聴くという行為について。誰しも、あらゆる音楽を楽しめるというわけではないんじゃないだろうか。色々聴いていたら、自分がの好みというのが出てくる。それは人それぞれで、例えばなんとなく好きな曲をより選んでいたら、そこにはいつもピアノが使われていた。そうか自分はピアノの音色が好きなんだみたいな。そして、より突っ込んで聴いていたら、どうやらピアノだけでは響かないらしい。ピアノの曲でかつ、同じフレーズが何度も繰り返されるような曲が好きであることに気づいた……みたいな。`}</p>
      <p>{`結果的にそうか自分はこういう音楽が好きで、これはジャズというジャンルで呼ばれているのだとか、そういう発見というか、探求というか。そういうのって音楽を好きで聞いている人であればみんなやってると思うのだけれど。あーそうか、これもこの人のいうPattern Matchingだな？と。`}</p>
      <h2 {...{
        "id": "ラーメン"
      }}>{`ラーメン`}</h2>
      <p>{`自分はラーメンが好きである。そういうラーメンが好きみたいな人は、ラーメンなら何でも良いんだろと言われれば全然そういうことでは無かったりする。自分なりに好きなラーメンにはルールがあって、それは例えばトンコツ系が苦手であるとか、魚介ダシが使われているのが好きだとか、麺はこういう食感のものが好きだとか。`}</p>
      <p>{`そういうのを続けていると、いろんなラーメンを食べていると、このラーメンは家系を意識しているが味が薄く、ベースとしては中華屋のあっさりしたラーメンであり、家系の味とはかなり違う。家系が好きな自分としては、好みかと言われるとだいぶ違う…… みたいなことを感じたりするようになる。ところがそういうラーメンをあんまり食べないうちの母親みたいな人間から言わせると、みんな同じラーメンで大した違いは無いとか。`}</p>
      <p>{`まぁそんなこんなで、ラーメンを好きでたくさん食べてる人も、ラーメンでPattern Matchingしてるよな？`}</p>
      <h2 {...{
        "id": "写真"
      }}>{`写真`}</h2>
      <p>{`写真を撮ることもPattern Matchingかと思った。これは自分にとってなのだが、自分はなんか写真を撮ってinstagramにアップするというのを繰り返していたら、なんだか`}<a parentName="p" {...{
          "href": "https://www.instagram.com/takazudo/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`電線ばかり撮っていた`}</a>{`ことに気づいた。そういうのを続けていたら、好きな感じの電線……それは角度だとか切り取り方だとかなのだけれど、そういうのがあることを認識できてきた。`}</p>
      <p>{`別にPattern Matchingという概念を知らなくとも、自分はPattern Matchingを繰り返していた。そして、これを続ければ続けるほど、そのPattern Matchingが洗練されてくる感覚というのが、この本を読んで示された気がした。`}</p>
      <h2 {...{
        "id": "仕事"
      }}>{`仕事`}</h2>
      <p>{`仕事についてもそうで、例えばデザインであったら、何かアプリのUIを作るみたいな話があったとして。そのためにはどういうUIがそのプロジェクトに適しているのかみたいな決定は、過去の経験だったり見てきたものからPattern Matchingして、得られた答えが形になるみたいな感じかと思う。`}</p>
      <p>{`プログラムであれば、ああ、こういうことをしたいんであれば、なんちゃらパターンで書くのが適しているとかどうとか。当然1回だけそれをやっただけでは分かりっこ無いが、これも経験や知識から最適な解が導けるという意味でPattern Matchingっぽい。CSS設計みたいな話もそういう要素はかなりある。`}</p>
      <p>{`もっと広い話で、こういうプロジェクトの要件の場合、こういうクライアントの場合は、こういう進め方が適しているとか、こういう見積もりの仕方が合っているとか、そのあたりにもPattern Matchingを感じる。`}</p>
      <h2 {...{
        "id": "be-a-pattern-matcher"
      }}>{`Be a Pattern Matcher`}</h2>
      <p>{`みたいなのを考えていたら、デザインをするみたいなことは基本的に自分のPattern Matchingの能力を行使する行為であり、好きな要素を寄せ集めて作るというので良いのだというのが腑に落ちた感じがした。しかし、その行為は誰かしらのまるっきりコピーにはならず、そこについても自分がこれが良い！と感じたこと、自分の完成でフィルタした要素の集まりになるなーみたいな。`}</p>
      <p>{`この takazudo.me というWebサイトのデザインは、大して時間をかけずに終えることができた。だいたいこういう自分が勝手に好きにするみたいな場合において、自分は無限に悩んで進まないみたいな事が多い。でも今回は、あーこれは自分の好きそうなやつでPattern Matchingして、それを集めて形にすれば良いんだなという感覚になっていた。`}</p>
      <p>{`結果、何か参考になりそうなWebサイトを`}<a parentName="p" {...{
          "href": "https://dribbble.com/shots/14481273-Portfolio-Courses-website-Blog",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Dribbleでササっと眺めつつ`}</a>{`、タグとかつけたいけどどーしようかなーと、適当に`}<a parentName="p" {...{
          "href": "https://dev.to",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`そういう機能が実装されているサービス`}</a>{`なんかを見て、余白の設計みたいなところは今まで仕事でやってきたやり方で実装するみたいな、なんかそういうので終えることができた感じがする。`}</p>
    </Body>
    <Outro mdxType="Outro">
洗練されている人は意識せずにPattern Matchingしているんではないかと思う。たぶん。自分もそうありたい。
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      